import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { Container } from "react-bootstrap";
import GlobalFooter from "../../components/GlobalFooter";
import GlobalNavBar from "../../components/GlobalNavBar";
import { ScrollRestoration } from 'react-router-dom';
import styled from 'styled-components';
import { CodeBlock, nord } from 'react-code-blocks';
import Accordion from 'react-bootstrap/Accordion';


const StylesDiv = styled.div`
    & {
        overflow-x: auto;
    }

    & img {
        width: 100%;
    }

    & .grid-layout {
        display: grid;
        column-gap: 1.5rem;
        row-gap: 1.5rem;

        @media (min-width: 990px) {
            // grid-auto-rows: 1fr;
            grid-template-columns: repeat(2, 1fr);
        }
    }
`

const ResponsiveContainer = styled.div`
    @media (min-width: 990px) {
        width: 60%;
    }
`

const Formalcomplexity = () => {
    const ease = [0.08, 0.37, 0.45, 0.89]

    useEffect(() => {
        if (typeof window?.MathJax !== "undefined") {
            window.MathJax.typeset()
        }
    }, [])

    return (<motion.article
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
        <>
            <GlobalNavBar />
            <ResponsiveContainer className="container p-3">
                <StylesDiv>
                    <h1 id="the-quadratic-space-complexity-of-the-parity-function-showing-that-47">The Quadratic Space Complexity of the Parity Function: Showing that {`\\(L_n(\\bigoplus_n) = n^2\\)`}</h1><p>The parity function on {`\\(n\\)`} inputs is defined as {`\\(\\bigoplus\\limits_n (x_1, ..., x_n) = x_1 \\oplus ... \\oplus x_n\\)`}. Here, {`\\(\\oplus\\)`} is the XOR function which is just the bitwise addition of bits modulo {`\\(2\\)`}. So, for instance: {`$$1 \\oplus 1 = 0$$`} {`$$0 \\oplus 1 = 1$$`} {`$$1 \\oplus 0 = 1$$`} {`$$0 \\oplus 0 = 0$$`} Back to topic, {`\\(\\bigoplus\\limits_n (x_1, ..., x_n)\\)`} is basically the {`\\(\\text{XOR}\\)`} of the {`\\(n\\)`} variables {`\\(x_1, ..., x_n\\)`}. Intuitively, it is {`\\(1\\)`} if there are an odd number of {`\\(1\\)`}s in the inputs, and {`\\(0\\)`} otherwise. Next, to introduce some notation, for any Boolean function {`\\(f\\)`}, let {`\\(L(f)\\)`} denote the leaf-size of the smallest boolean formula consisting exclusively of its inputs {`\\(x_1, ..., x_n\\)`} and the operations {`\\(\\wedge, \\vee, \\neg\\)`}.</p><p>The purpose of this blog post is to portray a pretty cool result that the leaf-size of the parity function on {`\\(n\\)`} inputs is {`\\(n^2\\)`}. It’s really neat that the leaf-size of the parity function is quadratic in the size of inputs, and it’s also quite unexpected. The following proof goes in stages. We first prove an upper bound on this result and then use the notion of formal complexity (through the Krapchenko function) to prove a lower bound to show that this quadratic result is tight.</p>
<Accordion className="mb-3">
    <Accordion.Item eventKey="0">
    <Accordion.Header>Lemma 1: {`\\(L(\\bigoplus_n)\\leq n^2\\)`} when {`\\(n\\)`} is a power of 2, where {`\\(L\\)`} is the leaf-size of the smallest formula that computes {`\\(\\bigoplus_n (x_1, ..., x_n)\\)`}</Accordion.Header>
    <Accordion.Body>
        <p>Given that {`\\(\\bigoplus\\limits_n(x_1, x_2, ..., x_n) = x_1 \\oplus ... \\oplus x_n\\)`} and {`\\(n = 2^k\\)`}, we can show that {`\\(L(\\bigoplus_n) \\leq n^2\\)`} by mathematical induction on the power that {`\\(n\\)`} is raised to.</p>
<p><u>Base Case</u>: {`\\(k = 0 \\Rightarrow n = 2^0 = 1\\)`}:</p>
<p>To show that {`\\(L(\\bigoplus_1) \\leq 1\\)`}, note that the leaf-size of the smallest formula that computes {`\\(\\bigoplus_1\\)`} is just {`\\(1\\)`}.</p>
<p>This is because the formula would just be composed of a single literal. Hence shown.</p>
<p><u>Assumptive Case</u>: We now assume that this formula is true for {`\\(k < p\\)`}:</p>
<p>Therefore, {`\\(L(\\bigoplus_{2k}) \\leq 2^k\\)`}, {`\\(\\forall k < p\\)`} is now assumed to be true.</p>
<p><u>Inductive Case</u>: To verify that the formula still holds true for {`\\(k = p\\)`}:</p>
<p>We need to show that {`\\(L(\\bigoplus_{2^p}) \\leq 2^p\\)`}. To do this, we note that {`\\(L(\\bigoplus_{2^p}) = L(\\bigoplus_{2^{p/2}} \\oplus \\bigoplus_{2^{p/2}})\\)`}. So, let {`$$A = (x_1 \\oplus ... \\oplus x_p), \\quad B = (x_{p+1} \\oplus ... \\oplus x_p)$$`}</p>
<p>Thus, {`\\(L(\\bigoplus_{2^p}) = L(A\\oplus B)\\)`}.</p>
<p>Since A and B are formulas of size {`\\(p\\)`}, we have that the leaf sizes of {`\\(A\\)`} and {`\\(B\\)`} are {`\\(\\leq (\\frac{p}{2})^2\\)`} (by the assumptive case).</p>
<p>So, we can compute {`\\(A \\oplus B\\)`} through the following Boolean formula (which follows by definition):</p>
<p>{`$$A \\oplus B = (A \\wedge \\neg B) \\vee (\\neg A \\wedge B)$$`}</p>
<p>Then, since this computation involves four of these leaves, we have that {`\\(L(\\bigoplus_{2^p}) = 4L(\\bigoplus_{2^{p/2}}) \\leq 4(\\frac{p}{2})^2 \\leq p^2\\)`}.</p>
<p><u>Conclusive Case</u>: We know that if the formula is true for {`\\(n^k\\)`}, then it is true for {`\\(n^{k+1}\\)`}, for {`\\(k \\in \\{\\mathbb{Z}_+ \\cup 0\\}\\)`}. From the base case, we know that it is true when {`\\(k = 0\\)`} or {`\\(n = 1\\)`}. Hence, the inequality is true {`\\(\\forall k, k \\in \\{\\mathbb{Z}_+ \\cup 0\\}\\)`}.</p>
<p>Therefore, we conclude that {`\\(L(\\bigoplus\\limits_n)\\leq n^2\\)`}, when {`\\(n\\)`} is a power of 2.</p>
    </Accordion.Body>
    </Accordion.Item>
</Accordion>
<p><strong>Definition 1:</strong> Formal Complexity Measures: A formal complexity measure (FC) is a function that maps Boolean functions on {`\\(n\\)`} variables to the natural numbers {`\\(\\mathbb{N}\\)`}, and satisfy the following properties:</p><p>{`\\(1)\\)`} {`\\(FC(x_i) = 1\\)`} for {`\\(1 \\leq i \\leq n\\)`}</p><p>{`\\(2)\\)`} {`\\(FC(f) = FC(\\neg f)\\)`} for all {`\\(f\\)`}</p><p>{`\\(3)\\)`} {`\\(FC(f \\wedge g) \\leq FC(f) + FC(g)\\)`} for all {`\\(f, g\\)`}</p>
<Accordion className="mb-3">
    <Accordion.Item eventKey="0">
    <Accordion.Header>Lemma 2: For every formal complexity measure {`\\(FC\\)`}, we have that {`\\(FC(f) \\leq L(f)\\)`}.</Accordion.Header>
    <Accordion.Body>
        <p>We can show that {`\\(FC(f) \\leq L(f)\\)`} for optimal formulas in {`\\(f\\)`} by using mathematical induction on {`\\(L(f)\\)`}. Note that</p>
<p><u>Base Case</u>: {`\\(L(f) = 1\\)`}</p>
<p>𝐿(𝑓) = 1 implies that 𝑓 is a literal (a formula of size 0). By definition, we have that {`\\(𝐿(𝑥_1) = 1\\)`}.</p>
<p>Note then that from property 1, {`\\(𝐹𝐶(𝑥_1) = 1\\)`} and by extension from property 2, {`\\(𝐹𝐶(¬𝑥_1) = 𝐹𝐶(𝑥_1) = 1\\)`}.</p>
<p>Therefore, for literals {`\\(𝐹𝐶(𝑓) = 𝐿(𝑓) = 1\\)`}, so {`\\(𝐹𝐶(𝑓) ≤ 𝐿(𝑓)\\)`} is satisfied. This satisfies the base case.</p>
<p><u>Assumptive Case</u>: Assume that {`\\(FC(f) \\leq L(f)\\)`} for optimal formulas for {`\\(f\\)`}.</p>
<p><u>Inductive Case</u>: For Boolean functions that act on optimal formulas to form more complex formulas:</p>
<p>Given the previous assumption, we can now consider operations that act on optimal Boolean functions.</p>
<p>Note that we only need to consider ∧,∨ operations, since negations can be transferred down to the leaves.</p>
<p>In the leaves, the literal on the corresponding leaves is negated the appropriate number of times.</p>
<p>Therefore, for {`\\(\\wedge\\)`} operations that act on optimal formulas {`\\(A\\)`} and {`\\(B\\)`}, let a Boolean function be {`\\(f = A \\wedge B\\)`}.</p>
<p>Then, the leaf size of the full formula will be: {`$$L(A \\wedge B) = L(A) + L(B)$$`}</p>
<p>Since {`\\(FC(A) \\leq L(A)\\)`} and {`\\(FC(B) \\leq L(B)\\)`} from the assumptive case, we have: {`$$L(A \\wedge B) \\geq FC(A) + FC(B)$$`}</p>
<p>From property 3, we have that {`\\(FC(A) + FC(B) = FC(A \\wedge B)\\)`}. Therefore, for {`\\(\\wedge\\)`} operations, {`$$L(A\\wedge B) \\geq FC(A\\wedge B) \\Rightarrow FC(f) \\leq L(f)$$`}</p>
<p>Then, for {`\\(\\vee\\)`} operations that act on optimal formulas {`\\(A\\)`} and {`\\(B\\)`}, let a Boolean function be {`\\(f = A \\vee B\\)`}.</p>
<p>Then, using De Morgan’s law: {`\\((A\\vee B) = \\neg(\\neg A \\wedge \\neg B)\\)`}. So, {`$$𝐹𝐶(𝐴 ∨ 𝐵) = 𝐹𝐶(¬(¬𝐴 ∧ ¬𝐵))$$`}</p>
<p>{`$$\\text{From property 2: } 𝐹𝐶(¬(¬𝐴 ∧ ¬𝐵)) = 𝐹𝐶(¬𝐴 ∧ ¬𝐵) \\Rightarrow 𝐹𝐶(𝐴 ∨ 𝐵) = 𝐹𝐶(¬𝐴 ∧ ¬𝐵)$$`}</p>
<p>{`$$\\text{From property 3: } 𝐹𝐶(¬𝐴 ∧ ¬𝐵) = 𝐹𝐶(¬𝐴) + 𝐹𝐶(¬𝐵) \\Rightarrow 𝐹𝐶(𝐴 ∨ 𝐵) = 𝐹𝐶(¬𝐴) + 𝐹𝐶(¬𝐵)$$`}</p>
<p>{`$$\\text{From property 2: } 𝐹𝐶(¬𝐴) = 𝐹𝐶(𝐴), 𝐹𝐶(¬𝐵) = 𝐹𝐶(𝐵) \\Rightarrow 𝐹𝐶(𝐴 ∨ 𝐵) = 𝐹𝐶(𝐴) + 𝐹𝐶(𝐵)$$`}</p>
<p>Then, from the assumptive case we have that: {`$$𝐹𝐶(𝐴) ≤ 𝐿(𝐴), 𝐹𝐶(𝐵) ≤ 𝐿(𝐵)$$`}</p>
<p>Therefore, we have that: {`$$𝐹𝐶(𝐴 ∨ 𝐵) ≤ 𝐿(𝐴) + 𝐿(𝐵)$$`}</p>
<p>Additionally, note that {`\\(L(A) + L(B) = L(A\\vee B)\\)`}. Therefore, {`$$𝐹𝐶(𝐴 ∨ 𝐵) ≤ 𝐿(𝐴 ∨ 𝐵) → 𝐹𝐶(𝑓) ≤ 𝐿(𝑓)$$`}</p>
<p>Thus, the inductive case is shown.</p>
<p>Therefore, we have now shown that {`\\(FC(f) \\leq L(f)\\)`} is true for Boolean functions that act on optimal expressions, provided that {`\\(FC(f) \\leq L(f)\\)`} is true for optimal expressions. Since we already know that the inequality is true for all literals (and their negations), it is then also true that for any optimal expression that builds on literals (every expression has some leaf-literals). Therefore, for every complexity measure {`\\(FC\\)`}, we have that {`\\(FC(f) \\leq L(f)\\)`}.</p>
    </Accordion.Body>
    </Accordion.Item>
</Accordion>
<p><strong>Definition 2:</strong> Krapchenko Formal Complexity Measures: Let {`\\(A, B \\subseteq \\{0, 1\\}^n\\)`}. Let {`\\(H(A, B) = \\{(a, b): a \\in A, b \\in B,\\)`} and a and b differ in exactly 1 coordinate{`\\(\\}\\)`}. Define {`$$ K(f) = \\max\\limits_{A \\subseteq f^{-1}(1), B \\subseteq f^{-1}(0)} \\frac{|H(A, B)|^2}{|A||B|}$$`}</p>
<Accordion className="mb-3">
    <Accordion.Item eventKey="0">
    <Accordion.Header>Lemma 3: Proving that {`\\(K\\)`} is a formal complexity measure.</Accordion.Header>
    <Accordion.Body>
        <p>To show that K is a formal complexity measure, we need to show that for some arbitrary Boolean formula {`\\(f\\)`}, that each of the three properties defined in Definition 1 are valid.</p>
<p><u>Property 1</u>: To show that {`\\(K(x_i) = 1, \\forall i, 1 \\leq i \\leq n\\)`}</p>
<p>We show that when {`\\(f\\)`} is a literal such that {`\\(f(x) = x_i\\)`}, then {`\\(K(f(x)) = K(x_i) = 1\\)`}. Then, define the following:</p>
<p>{`$$A = \\{0^n\\}, \\quad B = \\{0^{n-1}10^{i-1}\\}$$`}</p>
<p>Since {`\\(A\\)`} and {`\\(B\\)`} differ in exactly {`\\(1\\)`} coordinate by our construction of {`\\(A\\)`} and {`\\(B\\)`},</p>
<p>{`$$|𝐻(𝐴, 𝐵)| = |𝐴| = |𝐵| = 1$$`}</p>
<p>{`$$|𝐻(𝐴, 𝐵)|^2 ≤ |𝐴||𝐵|$$`}</p>
<p>{`$$K(x_i) = \\max\\limits_{A \\subseteq x_i^{-1}(1), B\\subseteq x_i^{-1}(0)} \\frac{|H(A, B)|^2}{|A||B|} \\leq 1 \\quad \\text{(an upper bound of 1)}$$`}</p>
<p>Additionally, for sets {`\\(A \\subseteq f^{-1}(0), B\\subseteq f^{-1}(1)\\)`},</p>
<p>{`$$\\frac{|𝐻(𝐴,𝐵)|}{|A|} \\leq 1 \\text{ and } \\frac{|𝐻(𝐴,𝐵)|}{|B|} \\leq 1$$`}</p>
<p>{`$$K(x_i) = \\max\\limits_{A \\subseteq x_i^{-1}(1), B\\subseteq x_i^{-1}(0)} \\frac{|H(A, B)|^2}{|A||B|} \\geq 1 \\quad \\text{(a lower bound of 1)}$$`}</p>
<p>Since we have that {`\\(K(x_i) \\geq 1\\)`} and {`\\(K(x_i) \\leq 1\\)`}, we have that {`\\(K(x_i) = 1\\)`}.</p>
<p><u>Property 2</u>: To show that {`\\(𝐾(𝑓) = 𝐾(¬𝑓)\\)`}:</p>
<p>Note that we can simply interchange A and B since the formula would not change:</p>
<p>{`$$K(f) = \\max\\limits_{A \\subseteq f^{-1}(1), B \\subseteq f^{-1}(0)} \\frac{|H(A, B)|^2}{|A||B|} = \\max\\limits_{B \\subseteq f^{-1}(1), A \\subseteq f^{-1}(0)} \\frac{|H(A, B)|^2}{|A||B|} = K(\\neg f)$$`}</p>
<p>Therefore, negating the boolean formula would not change the output. Hence, {`\\(𝐾(𝑓) = 𝐾(¬𝑓)\\)`}.</p>
<p><u>Property 3</u>: To show that {`\\(𝐾(𝑓 ∧ 𝑔) ≤ 𝐾(𝑓) + 𝐾(𝑔)\\)`}:</p>
<p>Let {`\\(A\\)`} and {`\\(B\\)`} be subsets maximizing the expression that defines {`\\(𝐾(𝑓 ∧ 𝑔)\\)`}.</p>
<p>Next, partition {`\\(𝐴\\)`} into {`\\(𝐴_𝑓 ⊆ 𝑓^{−1}(1)\\)`} and {`\\(𝐴_𝑔 ⊆ 𝑔^{−1}(1)\\)`}. Then,</p>
<p>{`$$𝐻 ( 𝐴 , 𝐵 ) = 𝐻 ( 𝐴_𝑓 , 𝐵 ) \\cup 𝐻 ( 𝐴_𝑔 , 𝐵 )$$`}</p>
<p>Note that</p>
<p>{`$$\\frac{|H(A_f, B_f)|^2}{|A_f||B_f|} \\leq K(f), \\quad \\text{where }B_f \\subseteq f^{-1}(0)$$`}</p>
<p>{`$$\\frac{|H(A_g, B_g)|^2}{|A_g||B_g|} \\leq K(g), \\quad \\text{where }B_g \\subseteq g^{-1}(0)$$`}</p>
<p>Therefore, the onus of this proof is to show that:</p>
<p>{`$$K(f\\wedge g) = \\frac{|H(A_f, B_f) + H(A_g, B_g)|^2}{(|A_f| + |A_g|)|B|} \\leq \\frac{|H(A_f, B_f)|^2}{|A_f||B|} + \\frac{|H(A_g, B_g)|^2}{|A_g||B|}$$`}</p>
<p>To show this, we try to reduce the above expression to a known statement:</p>
<p>{`$$\\frac{|H(A_f, B_f) + H(A_g, B_g)|^2}{(|A_f| + |A_g|)|B|} \\leq \\frac{|H(A_f, B_f)|^2}{|A_f||B|} + \\frac{|H(A_g, B_g)|^2}{|A_g||B|}$$`}</p>
<p>Multiplying both sides by {`\\((|𝐴_𝑓| + |𝐴_𝑔|)|𝐴_𝑓||𝐴_𝑔||𝐵|\\)`}:</p>
<p>{`$$|𝐻(𝐴_f, 𝐵_f) + 𝐻(𝐴_g, 𝐵_g)|^2 |𝐴_f||𝐴_g| ≤ (|𝐴_f|+ |𝐴_g|)|𝐴_f|(|𝐻(𝐴_f,𝐵_f)|^2 +|𝐻(𝐴_g,𝐵_g)|^2|𝐴_f|)$$`}</p>
<p>Expanding the terms out:</p>
<p>{`$$(𝐻(𝐴_f,𝐵_f)^2|𝐴_f||𝐴_g|+𝐻(𝐴_g,𝐵_g)^2|𝐴_f||𝐴_g|+2𝐻(𝐴_f,𝐵_f)𝐻(𝐴_g,𝐵_g)|𝐴_f||𝐴_g|)$$`} {`$$\\leq |𝐻(𝐴_f ,𝐵-f )|^2|𝐴_f ||𝐴_f |+|𝐻(𝐴_f ,𝐵_f )|^2|𝐴_f ||𝐴_g |+|𝐻(𝐴_g ,𝐵_g )|^2|𝐴_g||𝐴_f| + |𝐻(𝐴_g,𝐵_g)|^2|𝐴_g||𝐴_g|$$`}</p>
<p>Subtracting congruent terms and taking everything to the RHS, we have:</p>
<p>{`$$0 ≤ |𝐻(𝐴_f ,𝐵_f )|^2|𝐴_f|2 +|𝐻(𝐴_g ,𝐵_g )|^2|𝐴_g|2 −2𝐻(𝐴_f ,𝐵_f )𝐻(𝐴_g ,𝐵_g )|𝐴_f ||𝐴_g |$$`}</p>
<p>Factorizing:</p>
<p>{`$$0 ≤ (|𝐻(𝐴_f , 𝐵_f )| |𝐴_f | − |𝐻(𝐴_g , 𝐵_g )| |𝐴_g | )^2$$`}</p>
<p>However, note that this is just the factorized form of {`\\((𝑎 − 𝑏)^2 ≥ 0\\)`}, which is known to be true. Hence,</p>
<p>{`$$K(f\\wedge g) = \\frac{|H(A_f, B_f) + H(A_g, B_g)|^2}{(|A_f| + |A_g|)|B|}$$`}</p>
<p>{`$$\\frac{|H(A_f, B_f)|^2}{|A_f||B|} + \\frac{|H(A_g, B_g)|^2}{|A_g||B|}$$`}</p>
<p>{`$$≤ 𝐾(𝑓) + 𝐾(𝑔)$$`}</p>
<p>Since we have shown that {`\\(𝐾(𝑓)\\)`} satisfies all {`\\(3\\)`} properties of formal complexity measures, we have that {`\\(𝐾(𝑓)\\)`} is a {`\\(FC\\)`} measure.</p>
    </Accordion.Body>
    </Accordion.Item>
</Accordion>

<Accordion className="mb-3">
    <Accordion.Item eventKey="0">
    <Accordion.Header>Lemma 4: Proving that {`\\(L(\\theta) \\geq n^2\\)`}.</Accordion.Header>
    <Accordion.Body>
        <p>Let {`\\(A \\subseteq \\bigoplus_n^{-1}(0), B \\subseteq \\bigoplus_n^{-1}(1) \\)`}.</p>
<p>It follows then that {`\\(∀𝑥\\)`} s.t. {`\\(\\bigoplus_n(𝑥) = 0\\)`}, all the {`\\(n\\)`} neighbors will have parity {`\\(1\\)`}, and {`\\(∀𝑥 s.t. \\bigoplus\\limits_n(x) = 1\\)`}, all the {`\\(n\\)`} neighbors will have parity {`\\(0\\)`} in the Boolean formula. Therefore,</p>
<p>{`$$|𝐻(𝐴, 𝐵)| = \\frac{𝑛(2^𝑛)}{2} = 2^{𝑛−1}𝑛$$`}</p>
<p>Additionally, we have that: {`$$|𝐴| = |𝐵| = 2^{𝑛−1}$$`}</p>
<p>Therefore, from the Krapchenko’s formal complexity measure defined in part c, we have that: {`$$𝐹𝐶(\\bigoplus_n) = \\frac{|𝐻(𝐴,𝐵)|^2}{|𝐴||𝐵|}$$`} {`$$=\\frac{(2^{𝑛−1}𝑛)^2}{(2^{n-1})^2} = \\frac{2^{2n-2}n^2}{2^{2n-2}} $$`} {`$$𝐹𝐶(\\bigoplus\\limits_𝑛)=n^2$$`}</p>
<p>However, from part b, we know that</p>
<p>{`$$𝐹𝐶(\\bigoplus_𝑛) ≤ 𝐿(\\bigoplus_𝑛)$$`}</p>
<p>Then, substituting our result that {`\\(𝐹𝐶(\\bigoplus_n) = 𝑛^2\\)`} into the above inequality, we have that {`$$𝐿 (\\bigoplus_n) ≥ 𝑛^2 $$`}</p>
<p>Hence shown.</p>
    </Accordion.Body>
    </Accordion.Item>
</Accordion>
<p>Combining Lemma 1 ({`\\(L(\\bigoplus_n) \\leq n^2\\)`}) and Lemma 4 ({`\\(L(\\bigoplus_n) \\geq n^2\\)`}) gives us the result that {`\\(L(\\bigoplus_n) = n^2\\)`}.</p>

                </StylesDiv>
            </ResponsiveContainer>
            <GlobalFooter />
        </>
        <ScrollRestoration />
    </motion.article>)
}

export default Formalcomplexity;

