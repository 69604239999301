import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { Container } from "react-bootstrap";
import GlobalFooter from "../../components/GlobalFooter";
import GlobalNavBar from "../../components/GlobalNavBar";
import { ScrollRestoration } from 'react-router-dom';
import styled from 'styled-components';
import { CodeBlock, nord } from 'react-code-blocks';
import Accordion from 'react-bootstrap/Accordion';


const StylesDiv = styled.div`
    & {
        overflow-x: auto;
    }

    & img {
        width: 100%;
    }

    & .grid-layout {
        display: grid;
        column-gap: 1.5rem;
        row-gap: 1.5rem;

        @media (min-width: 990px) {
            // grid-auto-rows: 1fr;
            grid-template-columns: repeat(2, 1fr);
        }
    }
`

const ResponsiveContainer = styled.div`
    @media (min-width: 990px) {
        width: 60%;
    }
`

const Samplingcrisis = () => {
    const ease = [0.08, 0.37, 0.45, 0.89]

    useEffect(() => {
        if (typeof window?.MathJax !== "undefined") {
            window.MathJax.typeset()
        }
    }, [])

    return (<motion.article
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
        <>
            <GlobalNavBar />
            <ResponsiveContainer className="container p-3">
                <StylesDiv>
                    <h1 id="identifying-chemicals-in-metabolomic-samples-through-dimensionality-reduction">Identifying Chemicals in Metabolomic Samples through Dimensionality Reduction</h1><p>During the Summer of 2021, I had the privilege of working with Professors Charles Steinhardt and Martin Hansen at the COSMIC Dawn Center of the Niels Bohr Institute at the University of Copenhagen, Denmark, on a research project that was funded by the Caltech Summer Undergraduate Research Fellowship. During this 11-week period, I created a semi-supervised machine learning model to identify chemicals by finding computational template bases for every chemical compound using non-negative matrix factorization. I was pleased to publish our results in a manuscript (“Identifying Chemicals in Metabolomic Samples through Dimensionality Reduction”, E. Anand, C. Steinhardt, M. Hansen, ArXiV preprint, 2021). Check out our paper below!</p><iframe src="https://drive.google.com/file/d/1GTEl29aqA1766gpWU-zp1UsZcX0Mcvsa/preview" width="100%" height="720"></iframe>

                </StylesDiv>
            </ResponsiveContainer>
            <GlobalFooter />
        </>
        <ScrollRestoration />
    </motion.article>)
}

export default Samplingcrisis;

