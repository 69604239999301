import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { Container } from "react-bootstrap";
import GlobalFooter from "../../components/GlobalFooter";
import GlobalNavBar from "../../components/GlobalNavBar";
import { ScrollRestoration } from 'react-router-dom';
import styled from 'styled-components';
import { CodeBlock, nord } from 'react-code-blocks';
import Accordion from 'react-bootstrap/Accordion';


const StylesDiv = styled.div`
    & {
        overflow-x: auto;
    }

    & img {
        width: 100%;
    }

    & .grid-layout {
        display: grid;
        column-gap: 1.5rem;
        row-gap: 1.5rem;

        @media (min-width: 990px) {
            // grid-auto-rows: 1fr;
            grid-template-columns: repeat(2, 1fr);
        }
    }
`

const ResponsiveContainer = styled.div`
    @media (min-width: 990px) {
        width: 60%;
    }
`

const Exp = () => {
    const ease = [0.08, 0.37, 0.45, 0.89]

    useEffect(() => {
        if (typeof window?.MathJax !== "undefined") {
            window.MathJax.typeset()
        }
    }, [])

    return (<motion.article
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
        <>
            <GlobalNavBar />
            <ResponsiveContainer className="container p-3">
                <StylesDiv>
                    <h1 id="enlarging-the-pseudorandomness-of-expander-random-walks">Enlarging the Pseudorandomness of Expander Random Walks</h1><p>For my senior thesis under the mentorship with <a href="http://users.cms.caltech.edu/~umans/">Professor Christopher Umans</a>, I am working to enlarge the pseudorandomnesss of expander random walks by finding classes of functions that cannot distinguish between the vertices sampled from an expander walk on vertices labeled {`\\(\\{1,\\dots,n\\}\\)`} and the vertices sampled from the uniform distribution on {`\\([n]\\)`}. Building off of previous research (see below), we are working to show that expander random walks are fooled by functions computed by {`\\(\\mathrm{ACC}^0\\)`} circuits. See my fall write-up for the project below!</p><iframe src="https://drive.google.com/file/d/1EN6dCjJIc8kDvk3TqfZP_-7PzBoi2xaM/preview" width="100%" height="720"></iframe><p>During my Junior year, I focused on understanding expander graphs. Over the year, we constructed a framework to study the model of random walks on expander graphs. A prior result shows that the sticky random walk can be a useful model to understand the full-scale expander graph. My research generalized the sticky random walk from {`\\(2\\)`} to {`\\(m\\)`} characters. See my write-up for the project below!</p><iframe src="https://drive.google.com/file/d/1LReIqT5rFgB8UqtdJKAoxpKkeSUEEdn3/preview" width="100%" height="720"></iframe>

                </StylesDiv>
            </ResponsiveContainer>
            <GlobalFooter />
        </>
        <ScrollRestoration />
    </motion.article>)
}

export default Exp;

