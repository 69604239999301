import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { Container } from "react-bootstrap";
import GlobalFooter from "../../components/GlobalFooter";
import GlobalNavBar from "../../components/GlobalNavBar";
import { ScrollRestoration } from 'react-router-dom';
import styled from 'styled-components';
import { CodeBlock, nord } from 'react-code-blocks';
import Accordion from 'react-bootstrap/Accordion';


const StylesDiv = styled.div`
    & {
        overflow-x: auto;
    }

    & img {
        width: 100%;
    }

    & .grid-layout {
        display: grid;
        column-gap: 1.5rem;
        row-gap: 1.5rem;

        @media (min-width: 990px) {
            // grid-auto-rows: 1fr;
            grid-template-columns: repeat(2, 1fr);
        }
    }
`

const ResponsiveContainer = styled.div`
    @media (min-width: 990px) {
        width: 60%;
    }
`

const Dimensionalityreduction = () => {
    const ease = [0.08, 0.37, 0.45, 0.89]

    useEffect(() => {
        if (typeof window?.MathJax !== "undefined") {
            window.MathJax.typeset()
        }
    }, [])

    return (<motion.article
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
        <>
            <GlobalNavBar />
            <ResponsiveContainer className="container p-3">
                <StylesDiv>
                    <h1 id="variance-propagation-in-dimensionality-reduction-embeddings">Variance Propagation in Dimensionality Reduction Embeddings</h1><p>During the spring quarter of my Junior year at Caltech, I took CS 159 which was a graduate-level class on ‘Advanced Topics in Machine Learning’ taught by Professor Yisong Yue. During the course, he asked us to pursue a ML-related research topic of our own interest. Advaith Sai (an exchange student from the University of Edinburgh) and I chose a topic of mutual interest - when performing dimensionality reduction on noisy datasets, is the noise uniformly embedded in the lower dimensional space? For this research topic, we presented a poster and submitted a final report outlining all the work we had done to answer the question as best as we could, and have attached the final report below! We’d like to particularly thank TAs Jennifer Sun and Christopher Yeh for their invaluable feedback during this project!</p><iframe src="https://drive.google.com/file/d/1GiRws2Neheayt8eOyOSVv7SaEi1r1VAI/preview" width="100%" height="720"></iframe>

                </StylesDiv>
            </ResponsiveContainer>
            <GlobalFooter />
        </>
        <ScrollRestoration />
    </motion.article>)
}

export default Dimensionalityreduction;

