import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { Container } from "react-bootstrap";
import GlobalFooter from "../../components/GlobalFooter";
import GlobalNavBar from "../../components/GlobalNavBar";
import { ScrollRestoration } from 'react-router-dom';
import styled from 'styled-components';
import { CodeBlock, nord } from 'react-code-blocks';
import Accordion from 'react-bootstrap/Accordion';


const StylesDiv = styled.div`
    & {
        overflow-x: auto;
    }

    & img {
        width: 100%;
    }

    & .grid-layout {
        display: grid;
        column-gap: 1.5rem;
        row-gap: 1.5rem;

        @media (min-width: 990px) {
            // grid-auto-rows: 1fr;
            grid-template-columns: repeat(2, 1fr);
        }
    }
`

const ResponsiveContainer = styled.div`
    @media (min-width: 990px) {
        width: 60%;
    }
`

const Stablesystems = () => {
    const ease = [0.08, 0.37, 0.45, 0.89]

    useEffect(() => {
        if (typeof window?.MathJax !== "undefined") {
            window.MathJax.typeset()
        }
    }, [])

    return (<motion.article
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
        <>
            <GlobalNavBar />
            <ResponsiveContainer className="container p-3">
                <StylesDiv>
                    <h1 id="online-adaptive-controller-selection-for-the-stable-control-of-noisy-dynamical-systems">Online Adaptive Controller Selection for the Stable Control of Noisy Dynamical Systems</h1><p>In the summer of 2020, I worked with Professor Adam Wierman and graduate student Yiheng Lin at the Rigorous Systems Research Group (RSRG) at Caltech, on a research project that was funded by the Associate’s Caltech SURF Fellowship. My research with Prof. Wierman focused on using perturbation analysis to prove stability bounds for model-predictive control on linear time-varying (LTV) systems in which the predictions of the disturbances are noisy, and extending this to linear feedback policies on the regularized online balanced descent (R-OBD) algorithm. We also showed more general results, which is published in our work (“Online Adaptive Controller Selection in Time-Varying Systems: No-Regret via Contractive Perturbation”, Y. Lin, J. Preiss, E. Anand, Y. Li, Y. Yue, A. Wierman), which is awaiting review at SIGMETRICS 2023. See our paper, and my first interim report below!</p><iframe src="https://drive.google.com/file/d/1UGjmHKX82Yuc75vrnQW5ZQ5rtpaAzh7J/preview" width="100%" height="720"></iframe><iframe src="https://drive.google.com/file/d/1rNXdwpy6326X20I0kAmx7KLdze2Ymw2p/preview" width="100%" height="720"></iframe>

                </StylesDiv>
            </ResponsiveContainer>
            <GlobalFooter />
        </>
        <ScrollRestoration />
    </motion.article>)
}

export default Stablesystems;

