import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import GlobalFooter from "../components/GlobalFooter";
import GlobalNavBar from "../components/GlobalNavBar";
import ProjectItem from '../components/ProjectItem';
import { URLS } from "../constants";
import { ScrollRestoration } from 'react-router-dom';
import styled from 'styled-components';
import candidate_finding from "../img/candidate_finding.webp";
import circuit_board from "../img/circuit_board.webp";
import SL_theorem from "../img/SL_theorem.png";
import branching_program from "../img/branching_program.webp";
import formal_complexity from "../img/formal_complexity.png";
import ecc from "../img/ecc.webp";
import martingale from "../img/martingale.jpg";
import karp_lipton from "../img/karp_lipton.png";
import better_mesh_plot from "../img/better_mesh_plot.png";
import meltdown_2 from "../img/meltdown-2.png";
import toda_theorem from "../img/toda_theorem.png";
import unary_language from "../img/unary_language.jpg";
import prisonersproblem from "../img/100prisonersproblem.jpg";
import vc from "../img/vc.png";
import chords_music from "../img/chords_music.png";
import embeddings from "../img/embeddings.png";
import metamaterial from "../img/metamaterial.png";
import tSNE from "../img/tSNE.png";
import covid19virus from "../img/covid19virus.png";
import rinderpest3 from "../img/rinderpest3.png";
import systems1 from "../img/systems1.png";
import hmm from "../img/hmm.png";
import filter from "../img/filter.png";
import airplane2 from "../img/airplane2.png";
import exp4 from "../img/exp4.png";

const Blog = () => {
    const ease = [0.08, 0.37, 0.45, 0.89]

    const GridContainer = styled.div`
        display: grid;
        column-gap: 1.5rem;
        row-gap: 1.5rem;

        @media (min-width: 990px) {
            // grid-auto-rows: 1fr;
            grid-template-columns: repeat(4, 1fr);
        }
    `

    useEffect(() => {
        if (typeof window?.MathJax !== "undefined") {
            window.MathJax.typeset()
        }
    }, [])

    return (<motion.article
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
        <>
            <GlobalNavBar />
            <Container className="py-4">
                <GridContainer>
                    <ProjectItem
    img={candidate_finding}
    title="An online algorithm to hire the best candidate"
    desc="Hiring the best secretary in an online setting with a \(3.6\) röntgen (not great, not terrible) probability."
    tags={['Theory CS']}
    route={URLS.online_secretary}
/>
<ProjectItem
    img={circuit_board}
    title="Relations on NL through Undirected ST-connectivity"
    desc="All problems in \(\mathrm{NL}_i\) have \(O(\log^{2i} n)\) depth, fan-in 2, Boolean circuits through \(\mathrm{USTCON}\)."
    tags={['Theory CS']}
    route={URLS.nl_relations}
/>
<ProjectItem
    img={SL_theorem}
    title="Unraveling the Sipser-Lautemann theorem"
    desc="Proving that \(\mathrm{BPP}\) is contained somewhere in the intersections of the polynomial hierarchy."
    tags={['Theory CS']}
    route={URLS.sipser_lautemann}
/>
<ProjectItem
    img={branching_program}
    title="Barrington’s theorem for Permutation Branching Programs for languages"
    desc="Showing that non-uniform \(\mathrm{NC}_1\) language has a polynomial-size width-5 branching program."
    tags={['Theory CS']}
    route={URLS.barrington_theorem}
/>
<ProjectItem
    img={formal_complexity}
    title="Musings on Krapchenko’s Formal Complexity"
    desc="Showing that the space complexity of the parity function, \(\mathrm L_n(\bigoplus_n)\), is quadratic in the number of its leaves, \(n\)."
    tags={['Theory CS']}
    route={URLS.formalcomplexity}
/>
<ProjectItem
    img={ecc}
    title="List Decoding of the BH, RS, and PV Codes"
    desc="List Decoding is really cool, but it also reveals some spectacular results in complexity theory."
    tags={['Theory CS']}
    route={URLS.listdecoding}
/>
<ProjectItem
    img={martingale}
    title="Using Martingales to Derive Robust Concentration Inequalities in Probability Theory"
    desc="Examining popular concentration inequalities and deriving strengthenings for Martingales."
    tags={['Mathematics']}
    route={URLS.martingale_inequalities}
/>
<ProjectItem
    img={karp_lipton}
    title="Proving the Karp-Lipton theorem"
    desc="A proof of Karp-Lipton’s theorem, which is a surprising statement about the polynomial hierarchy and symmetric quantifiers."
    tags={['Theory CS']}
    route={URLS.karplipton}
/>
<ProjectItem
    img={better_mesh_plot}
    title="Lipschitz functions and Bernstein polynomials"
    desc="Showing that Lipschitz functions can be approximated by Bernstein polynomials."
    tags={['Mathematics']}
    route={URLS.lipschitz_and_bernstein}
/>
<ProjectItem
    img={meltdown_2}
    title="Spectre and Meltdown Exploits"
    desc="Exploiting buffer overflows to execute code on remote machines and accessing the root kernel of an actual Intel processor."
    tags={['Computer Science']}
    route={URLS.shellcodemeltdownexploits}
/>
<ProjectItem
    img={toda_theorem}
    title="Proving Toda’s Theorem"
    desc="A proof of Toda’s theorem, which is that the polynomial hierarchy can be captured by the ability to count."
    tags={['Theory CS']}
    route={URLS.todatheorem}
/>
<ProjectItem
    img={unary_language}
    title="Mahaney’s Theorem on the Conditions for the NP-completeness of Sparse Languages"
    desc="A pretty interesting implication involving \(\mathrm{P}\), \(\mathrm{NP}\), unary, and sparse languages."
    tags={['Theory CS']}
    route={URLS.mahaney_theorem}
/>
<ProjectItem
    img={prisonersproblem}
    title="Permutations to Engineer Prison Breaks"
    desc="The role of cyclic and symmetric groups in making choices during extreme (hypothetical) life or death situations."
    tags={['Mathematics']}
    route={URLS.prisonersproblem}
/>
<ProjectItem
    img={vc}
    title="Intersections between ML and Complexity Theory"
    desc="Proving that the \(\mathrm{VC}\)-dimension, the measure of a finite dataset’s complexity, is \(\Sigma_3^\mathrm P\)-complete, and can therefore be computed by counting."
    tags={['Theory CS']}
    route={URLS.vcsigma}
/>
<ProjectItem
    img={chords_music}
    title="Why do most chords sound unpleasant to the ear?"
    desc="A beautiful probabilistic argument for why most chords sound unpleasant to the human ear."
    tags={['Mathematics']}
    route={URLS.unpleasant_chords}
/>
<ProjectItem
    img={embeddings}
    title="A Formal Introduction to Embeddings"
    desc="Low-dimension embeddings, Bourgain’s distortion proofs, and extensions to \(\ell_p\)-spaces."
    tags={['Theory CS']}
    route={URLS.embeddings}
/>
<ProjectItem
    img={metamaterial}
    title="LIGO: Acoustic Invisibility Cloak"
    desc="Using particle swarm optimization and finite element analysis to design/simulate acoustic band-limiting filters for the gravitational wave detector’s Cryo-Q upgrade."
    tags={['Physics']}
    route={URLS.ligosurf2020}
/>
<ProjectItem
    img={tSNE}
    title="Quantifying Noise in Dimensionality Reduction Methods"
    desc="Computing the noise propagation in t-stochastic neighbor embeddings (t-SNE) and uniform manifold approximation projection (UMAP)."
    tags={['Machine Learning']}
    route={URLS.dimensionalityreduction}
/>
<ProjectItem
    img={covid19virus}
    title="Correlating COVID-19 outbreaks to COVID-19 Vaccine Efficacy"
    desc="Using financial machine-learning models to quantify COVID-19 cases as a function of vaccination rates to predict outbreaks."
    tags={['Machine Learning']}
    route={URLS.covid19}
/>
<ProjectItem
    img={rinderpest3}
    title="Ph11 Hurdle 2: Modeling Rinderpest and Wildebeest Populations"
    desc="Modeled Wildebeest, Grass, and Rinderpest virus populations in the presence of forest fires using analytical factors of the Leslie Matrix."
    tags={['Computational Mathematics']}
    route={URLS.hurdle2}
/>
<ProjectItem
    img={systems1}
    title="Online Adaptive Controller Selection for the Stable Control of Noisy Dynamical Systems"
    desc="Proving that model predictive control and regularized online balanced descent with noisy predictions can control dynamical systems."
    tags={['Machine Learning']}
    route={URLS.stablesystems}
/>
<ProjectItem
    img={hmm}
    title="Hidden Markov Model to Generate Shakespearean Sonnets"
    desc="Created an unsupervised Hidden Markov model using the Viterbi algorithm to generate links between word-phrases, and trained the HMM to generate novel Shakespearean sonnets."
    tags={['Machine Learning']}
    route={URLS.hmm}
/>
<ProjectItem
    img={filter}
    title="Identifying Chemicals in Metabolomic Samples through Dimensionality Reduction"
    desc="Identifying Chemicals using Mass-Spec data, aided by t-stochastic neighbor embedding (t-SNE) and Nonnegative Matrix Factorization (NMF)."
    tags={['Machine Learning']}
    route={URLS.samplingcrisis}
/>
<ProjectItem
    img={airplane2}
    title="Ph11 Hurdle 1: Modeling Airplane Delays at SFO"
    desc="Creating a mathematical model for the delays of airplanes at the San Francisco Airport during the closure of runway 85A."
    tags={['Computational Mathematics']}
    route={URLS.hurdle1}
/>
<ProjectItem
    img={exp4}
    title="Enlargening the Pseudorandomness of Expander Random Walks"
    desc="Finding classes of test-functions that are fooled by random walks on expander graphs."
    tags={['Theory CS']}
    route={URLS.exp}
/>
                </GridContainer>
            </Container>
            <GlobalFooter />
        </>
    </motion.article>)
}

export default Blog;

