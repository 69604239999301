import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { Container } from "react-bootstrap";
import GlobalFooter from "../../components/GlobalFooter";
import GlobalNavBar from "../../components/GlobalNavBar";
import { ScrollRestoration } from 'react-router-dom';
import styled from 'styled-components';
import { CodeBlock, nord } from 'react-code-blocks';
import Accordion from 'react-bootstrap/Accordion';


const StylesDiv = styled.div`
    & {
        overflow-x: auto;
    }

    & img {
        width: 100%;
    }

    & .grid-layout {
        display: grid;
        column-gap: 1.5rem;
        row-gap: 1.5rem;

        @media (min-width: 990px) {
            // grid-auto-rows: 1fr;
            grid-template-columns: repeat(2, 1fr);
        }
    }
`

const ResponsiveContainer = styled.div`
    @media (min-width: 990px) {
        width: 60%;
    }
`

const Ligosurf2020 = () => {
    const ease = [0.08, 0.37, 0.45, 0.89]

    useEffect(() => {
        if (typeof window?.MathJax !== "undefined") {
            window.MathJax.typeset()
        }
    }, [])

    return (<motion.article
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
        <>
            <GlobalNavBar />
            <ResponsiveContainer className="container p-3">
                <StylesDiv>
                    <h1 id="ligo-acoustic-invisibility-cloak">LIGO: Acoustic Invisibility Cloak</h1><p>During the Summer of 2020, I had the privilege of working with the Laser Interferometer Gravitational-Wave Observatory (LIGO) and Professor Rana Adhikari on a research project that was funded by the Richard Brewer Fellowship Prize. During this 10-week period, I worked on an element of the LIGO Voyager Cryo-Q upgrade: the gravitational-wave detector had started to get so sensitive that it was detecting the thermal noise from its own Silicon atoms located in the Fabry-Perot mirror cavity. So, my research focused on finding a way to block the tranmission of noise (the natural vibrating frequency of Silicon) using band-limiting filters, and designing different acoustic geometries which I simulated using finite element analysis. See my final report below!</p><iframe src="https://drive.google.com/file/d/1eDuB884wasiRaMif3AJ1MNfD5oe5yF5z/preview" width="100%" height="720"></iframe>

                </StylesDiv>
            </ResponsiveContainer>
            <GlobalFooter />
        </>
        <ScrollRestoration />
    </motion.article>)
}

export default Ligosurf2020;

